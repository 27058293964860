import './App.css';
import {useEffect, useState} from "react";

function App() {
  const [todos, setTodos] = useState([]);

  useEffect(async () => {
    const request = await fetch("/api/todos");
    const response = await request.json();
    setTodos(response);
      console.log(todos);
  },[]);

  return (
    <div className="App">
      <header className="App-header">
        {todos && todos.length && (
          todos.map((todo) => (<p>{todo}</p>))
        )}
        <p></p>
      </header>
    </div>
  );
}

export default App;
